<template>
  <div class="error-page">
    <p class="tips-404">
      <span>哎呀，出错了！没有找到您需要的页面......</span>
    </p>
    <p class="icon-404">404</p>
    <p class="tips-text">您可以通过一下方式继续访问</p>
    <p class="back">
      <span class="btn" @click="viewHome">访问首页</span>
    </p>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies';
import { mapState } from 'vuex';
import { err404Log, baiduLog } from '@/util/log.js';
let FormlY = '';
export default {
  name: 'PAGE404',
  data() {
    return {
      pagetype: '404',
      newstype: '404',
    };
  },
  beforeRouteEnter(to, from, next) {
    FormlY = from.fullPath;
    next();
  },
  mounted() {
    this.reportLog();
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    // 日志上报
    reportLog() {
      let pdata = VueCookies.get('TJ_DATA');
      VueCookies.remove('TJ_DATA');
      if (pdata) {
        pdata = pdata.split('|');
      } else {
        pdata = [];
      }
      let vfrom = '';
      if (!this.hasUseRefer && document.referrer) {
        vfrom = document.referrer;
        this.$store.commit('SET_HASUSEERFER', true);
      } else {
        if (!FormlY || FormlY === '/') vfrom = 'null';
        else vfrom = `${location.protocol}//${location.host}${FormlY}`;
      }
      baiduLog();
      err404Log({
        accid: this.userInfo ? this.userInfo.id : -1,
        btype: pdata[0] || 'null',
        subtype: pdata[1] || 'null',
        pageType: this.pagetype,
        idx: pdata[2] || 'null',
        newstype: this.newstype,
        pgnum: pdata[4] || 'null',
        from: vfrom,
        to: window.location.href,
        fr_url: vfrom,
      });
    },
    viewHome() {
      this.$router.push({
        path: '/talk/home',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.error-page {
  margin-top: 30px;
  width: 1060px;
  padding-top: 200px;
  height: calc(100vh - 90px);
  text-align: center;
  background-color: #fff;
  color: #1985ff;
  box-sizing: border-box;
  .tips-404 {
    line-height: 36px;
    height: 36px;
    font-size: 20px;
    margin-bottom: 5px;
    span {
      padding-left: 50px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 36px;
        height: 36px;
        background: url(../../assets/image/404_icon.png) no-repeat center center;
      }
    }
  }
  .icon-404 {
    font-size: 122px;
    font-weight: bold;
  }
  .tips-text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 14px;
    margin-bottom: 23px;
  }
  .back {
    height: 40px;
    .btn {
      cursor: pointer;
      display: inline-block;
      line-height: 24px;
      height: 24px;
      padding: 8px 32px;
      background-color: #1984ff;
      text-align: center;
      color: #fff;
      border-radius: 3px;
    }
  }
}
</style>
